import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';

function Features() {
  return (
    <div className="App-view">
      <ListGroup>
        <ListGroup.Item>
          Need help to make your designs available via PartCAD? We are here to help!
        </ListGroup.Item>
        <ListGroup.Item>
          Do you want to use innovative ways to demonstrate how to use your unique parts? Thats easy!
        </ListGroup.Item>
        <ListGroup.Item>
          Looking to migrate off of excessively expensive CAD or PLM tools? Ask us how!
        </ListGroup.Item>
      </ListGroup>
      <center style={{ width: "75%", marginTop: 40 }}>
        Contact <Link to="mailto:support@partcad.org">support@partcad.org</Link> for consulting inquiries.
      </center>
    </div>
  );
}

export default Features;
