import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
// import photo_q from '../assets/images/q.png';
import photo_roman from '../assets/images/roman_kuzmenko.jpg';

function About() {
  const style_container = {
    paddingTop: "0px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_card = {
    margin: "15px",
    width: '18rem',
    // border: "none",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    fontWeight: 1200,
  };
  const style_text = {
    paddingTop: "20px",
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
      <Container style={style_container}>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            {/* <Card.Img variant="top" style={style_image} src={photo_q} /> */}
            <p style={{textAlign: "center", fontSize: 140}}>?</p>
            <Card.Title style={style_title}>Is this you?<br/><br/></Card.Title>
            {/* <span class="material-icons-outlined">question_mark</span> */}
            {/* <Card.Img variant="top" style={style_image} src="question_mark" /> */}
            {/* <Card.Img><span class="material-symbols-outlined">question_mark</span></Card.Img> */}
            <Card.Title style={style_title}>Wanted non-tech and tech co-founders (especially mechanical engineering, additive manufacturing and AI)</Card.Title>
          </Card.Body>
        </Card>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Img variant="top" style={style_image} src={photo_roman} />
            <Card.Title style={style_title}>Roman Kuzmenko</Card.Title>
            <Card.Text style={style_text}>
              <a style={style_link} href="https://www.linkedin.com/in/romankuzmenko/">romankuzmenko</a>
              <a style={style_link} href="mailto:kuzmenkor@gmail.com">kuzmenkor@gmail.com</a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
      <div style={{ textAlign: "center" }}>
        PartCAD is a former component of <Link to="https://github.com/openvmp/openvmp">OpenVMP</Link> which has its own life now.
        We're a passionate team in the pre-seed phase,
        developing a groundbreaking solution for collaboration on CAD designs.
        <br /><br />
        We're actively exploring funding options, including bootstrapping and strategic partnerships.<br />
        Want to be part of the story? Reach out to <Link to="mailto:support@partcad.org">support@partcad.org</Link>
      </div>
    </div>
  );
}

export default About;
