function Support() {
  return (
    <div className="App-view">
      <p>
        Please, contact <a href="mailto:support@partcad.org">support@partcad.org</a> with any questions.
      </p>
    </div>
  );
}

export default Support;
